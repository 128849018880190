import { useMemo } from "react";
import { getPackageInstanceAppointments } from "api-services/definitions/package-instances";
import { useApiGet } from "api-services/endpoints";

import usePackageInstance from "@hooks/use-package-instance";
import {
  countPackageInstancesAppointmentsMap,
  getPackageInstanceTag,
} from "@lib/packages/package-instances";

import Tag from "@components/Tag/Tag";
import TypeIllustration from "@components/TypeIllustration";

const PackageInstanceSubtitle = ({
  organizationId,
  appointmentId,
  contactId,
  packageInstanceId,
}: {
  organizationId: string;
  appointmentId: string;
  contactId: string;
  packageInstanceId: string;
}) => {
  const { packageInstance, loading: loadingPackageInstance } =
    usePackageInstance(organizationId, packageInstanceId);

  const { data } = useApiGet(
    getPackageInstanceAppointments,
    {
      packageInstanceId,
      userId: organizationId,
      clientId: contactId,
    },
    {},
    { revalidateOnFocus: false, dedupingInterval: 30000 }
  );
  const packageInstanceAppointments = data?.packageInstanceAppointments;

  const packageInstancesAppointmentsMap = useMemo(
    () =>
      countPackageInstancesAppointmentsMap(
        packageInstance ? [packageInstance] : undefined,
        packageInstanceAppointments
      ),
    [packageInstance, packageInstanceAppointments]
  );

  if (loadingPackageInstance) {
    return (
      <div className="flex flex-1 space-x-4 items-center h-6">
        <div className="bg-grey-900 dark:bg-grey-250 animate-pulse w-6 h-6 rounded-full font-medium"></div>
        <div className="bg-grey-900 dark:bg-grey-250 animate-pulse w-20 h-1 rounded-full font-medium"></div>
      </div>
    );
  }

  if (!packageInstance) return "No Package";

  const { icon, title, id } = packageInstance;
  const packageInstanceTag = getPackageInstanceTag(
    id,
    appointmentId,
    packageInstancesAppointmentsMap
  );
  return (
    <div className="flex gap-2 items-center">
      <TypeIllustration slug={icon} width={24} height={24} />
      {title}
      {packageInstanceTag && <Tag>{packageInstanceTag}</Tag>}
    </div>
  );
};

export default PackageInstanceSubtitle;
