import React from "react";

import { useAuth } from "@contexts/auth";

import ActiveDashboardCard from "@components/Home/ActiveDashboardCard";
import StaticDashboardCard from "@components/Home/StaticDashboardCard";
import CalendarIcon from "@components/Icons/CalendarIcon";

interface AppointmentsDashboardCardProps {
  className?: string;
  isStatic: boolean;
  isSelected: boolean;
}

const AppointmentsDashboardCard: React.FC<AppointmentsDashboardCardProps> = ({
  className,
  isStatic,
  isSelected,
}) => {
  const { oid, aid } = useAuth();

  const Icon = () => (
    <div className="bg-blue-950 p-1.5 rounded-full mr-2">
      <CalendarIcon className="w-3.5 h-3.5 text-blue-300" />
    </div>
  );

  if (isStatic) {
    return (
      <StaticDashboardCard
        className={className}
        href={`/api/gauth/${oid}?accountId=${aid}`}
        title="Appointments"
        number={1}
        description="See all your past and future appointments."
        buttonText="Connect calendar"
        isSelected={isSelected}
      />
    );
  }

  return (
    <ActiveDashboardCard
      className={className}
      href="/appointments"
      title="Appointments"
      description="Successfully connected"
      DescriptionIcon={Icon}
    />
  );
};

export default AppointmentsDashboardCard;
