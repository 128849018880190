import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import { useBillingRatesContext } from "@contexts/billing-rates";

import MemberFilter from "@components/MemberFilter";
import ActiveClientsCard from "@components/TodaysView/ActiveClientsCard";
import ActivePackagesCard from "@components/TodaysView/ActivePackagesCard";
import SessionsByOutcomeCard from "@components/TodaysView/SessionsByOutcomeCard";
import SessionsBySchedulerCard from "@components/TodaysView/SessionsBySchedulerCard";
import { useTodaysViewFiltersContext } from "@components/TodaysView/TodaysViewContext";
import TodaysViewDateFilter from "@components/TodaysView/TodaysViewDateFilter";

import SessionBillingDashboardCard from "./SessionBillingDashboardCard";

const DashboardCardsList = () => {
  const { organization } = useAuth();

  const { canAccessTheFeature, hasSessionBillingSettings } =
    useBillingRatesContext();

  const { filters, setFilters } = useTodaysViewFiltersContext();

  const hasAccessAndBilling = canAccessTheFeature && hasSessionBillingSettings;

  const renderSessionBillingCard = hasAccessAndBilling &&
    organization?.stripe?.stripe_user_id && <SessionBillingDashboardCard />;

  const renderMemberFilter = (
    <MemberFilter setFilters={setFilters} defaultValue={filters?.memberId} />
  );

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-black-ink text-xl font-regular">
          Activity dashboard
        </h2>
        {renderMemberFilter}
      </div>
      <div className="my-6">
        <div
          className={classNames(
            "grid gap-3 sm:grid-cols-2",
            hasAccessAndBilling ? "md:grid-cols-3" : "md:grid-cols-2"
          )}
        >
          <ActivePackagesCard />
          <ActiveClientsCard />
          {renderSessionBillingCard}
        </div>

        <div className="mt-6 pt-3">
          <div className="w-full flex justify-between items-center mb-8">
            <h1 className="text-black-ink text-xl font-regular">
              Session activity
            </h1>
            <div className="flex gap-3">
              {renderMemberFilter}
              <TodaysViewDateFilter />
            </div>
          </div>
          <div className="grid gap-3 sm:grid-cols-2 ">
            <SessionsBySchedulerCard />
            <SessionsByOutcomeCard />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCardsList;
