import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { putAppointmentPrimaryContact } from "api-services/definitions/appointments";
import { getContactAppointments } from "api-services/definitions/contact-appointments";
import { useApi, useApiGetMutate } from "api-services/endpoints";

import { useAuth } from "@contexts/auth";
import { useContact } from "@hooks/data/clients";
import useAppointment from "@hooks/use-appointment";
import useSnackbar from "@hooks/use-snackbar";
import { useUpdateAppointmentsCache } from "@hooks/useUpdateAppointmentsCache";
import { displayNameFromContact } from "@lib/contacts";
import { AppointmentType } from "@lib/data/schemas/appointment";

import SelectForm from "@components/Form/SelectForm";
import StarIcon from "@components/Icons/StarIcon";
import SmallModal from "@components/Modals/SmallModal";

interface ClientLabelProps {
  clientId: string;
}

const ClientLabel: FC<ClientLabelProps> = ({ clientId }) => {
  const { contact } = useContact(clientId);

  return <>{displayNameFromContact(contact)}</>;
};

const description = (
  <h3 className="pt-4">
    Set the primary assigned contact for this appointment. We&apos;ll still show
    the session on the record of all attendees. This change will affect all
    appointments in the series.
  </h3>
);

export interface SetPrimaryContactModalWrapperProps {
  appointmentId: string;
  setShowModal: (showModal: boolean) => void;
}

export const SetPrimaryContactModalWrapper: FC<
  SetPrimaryContactModalWrapperProps
> = ({ appointmentId, setShowModal }) => {
  const { oid } = useAuth();
  const { appointment } = useAppointment(oid, appointmentId);

  if (!appointment) {
    return (
      <SmallModal
        show
        toggleShow={setShowModal}
        title="Set primary contact"
        icon={StarIcon}
        iconColor="grey"
        description={description}
        onActionText="Confirm assigned contact"
        onActionLoading={true}
      ></SmallModal>
    );
  }

  return (
    <SetPrimaryContactModal
      appointment={appointment}
      setShowModal={setShowModal}
    />
  );
};

export interface SetPrimaryContactModalProps {
  appointment: AppointmentType;
  setShowModal: (showModal: boolean) => void;
}

export type SetPrimaryContactModalFieldTypes = {
  clientId?: string;
};

const SetPrimaryContactModal: FC<SetPrimaryContactModalProps> = ({
  appointment,
  setShowModal,
}) => {
  const { aid, oid } = useAuth();
  const { apiCall: updateAppointmentPrimaryContact } = useApi(
    putAppointmentPrimaryContact,
    {
      failMode: "throw",
    }
  );
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const formMethods = useForm<SetPrimaryContactModalFieldTypes>({
    defaultValues: {
      clientId: appointment?.contactId ?? undefined,
    },
  });

  const { handleSubmit, control } = formMethods;

  const clientArray =
    appointment.contactIds?.flatMap((clientId) => {
      return {
        value: clientId,
        label: <ClientLabel clientId={clientId} />,
      };
    }) ?? [];

  const mutateContactAppointments = useApiGetMutate(
    oid && appointment.contactId ? getContactAppointments : undefined,
    {
      orgId: oid ?? "",
      contactId: appointment.contactId ?? "",
    },
    undefined,
    {
      ignoreQuery: true,
    }
  );
  const { onUpdateAppointment } = useUpdateAppointmentsCache();

  const onSubmit = async (data: SetPrimaryContactModalFieldTypes) => {
    setLoading(true);
    try {
      const payload = {
        contactId: data.clientId!,
        memberId: aid!,
      };
      await updateAppointmentPrimaryContact(
        { userId: oid!, appointmentId: appointment.id },
        payload,
        {}
      );
      await mutateContactAppointments();
      await onUpdateAppointment(appointment.id);
      snackbar.showMessage("Appointment primary contact saved successfully");
    } catch (e) {
      snackbar.showWarning("Error saving appointment primary contact");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SmallModal
      show
      toggleShow={setShowModal}
      title="Set primary contact"
      icon={StarIcon}
      iconColor="grey"
      description={description}
      onAction={handleSubmit(onSubmit)}
      onActionText="Confirm assigned contact"
      onActionLoading={loading}
      isActionDisabled={clientArray.length === 0}
    >
      <FormProvider {...formMethods}>
        <SelectForm
          control={control}
          name="clientId"
          options={clientArray}
          indicatorSeparator={false}
          label="Primary assignee"
        />
      </FormProvider>
    </SmallModal>
  );
};

export default SetPrimaryContactModal;
