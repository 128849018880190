import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Tier } from "@practice/authz";
import { differenceInHours } from "date-fns";
import Stripe from "stripe";

import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import useFailedIntegrationMonitor from "@hooks/use-failed-integration-monitor";
import { useGetCalendars } from "@hooks/use-get-integrations";
import usePersistentState from "@hooks/use-persistent-state";
import { hasEmail } from "@lib/utils/email";

import AppFrame from "@components/App/AppFrame";
import AppointmentList from "@components/AppointmentList";
import SendEmailModal from "@components/Email/SendEmailModal";
import DashboardCardsList from "@components/Home/DashboardCardsList";
import DashboardWithOnboarding from "@components/Home/DashboardWithOnboarding";
import GetStartedVideosSection from "@components/Home/GetStartedVideos/GetStartedVideoCardSection";
import CalendarIcon from "@components/Icons/CalendarIcon";
import ClientIcon from "@components/Icons/ClientIcon";
import EmailIcon from "@components/Icons/EmailIcon";
import FormIcon from "@components/Icons/FormIcon";
import InvoiceIcon from "@components/Icons/InvoiceIcon";
import SocialWebIcon from "@components/Icons/SocialWebIcon";
import UploadIcon from "@components/Icons/UploadIcon";
import LoadingSpinner from "@components/LoadingSpinner";
import { LinkFormModal } from "@components/Modals";
import TiersWelcomeModal from "@components/Modals/TiersWelcomeModal";
import WelcomeModal from "@components/Modals/WelcomeModal/WelcomeModal";
import { TodaysViewFilterProvider } from "@components/TodaysView/TodaysViewContext";
import { UploadModal } from "@components/UploadModal";

const Home = () => {
  const { organization, oid, subscription, account, isOwner } = useAuth();

  const { hasFullAccess, hasElevatedAccess, hasLimitedAccess } =
    useAccessType();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showProModal, setShowProModal] = useState(false);
  const [showNewEmailModal, setShowNewEmailModal] = useState<boolean>(false);

  const canViewGetStartedVideos = !hasLimitedAccess;

  const subscriptionStatus = subscription?.status as
    | Stripe.Subscription.Status
    | undefined;

  useFailedIntegrationMonitor();
  const {
    persistentValue: seenWelcomeModal,
    persistentSetValue: setSeenWelcomeModal,
  } = usePersistentState<Date | null>("seenWelcomeModal", null);
  const { persistentValue: seenProModal, persistentSetValue: setSeenProModal } =
    usePersistentState<Date | null>("seenProModal", null);

  const { accounts } = useGetCalendars(!hasFullAccess);
  const hasCalendarIntegrated = React.useMemo(
    () => !!accounts?.length,
    [accounts]
  );

  useEffect(() => {
    // Migrate from boolean to date string if necessary
    if (typeof seenWelcomeModal === "boolean") {
      setSeenWelcomeModal(seenWelcomeModal ? new Date() : null);
    }

    if (!seenWelcomeModal && subscriptionStatus === "trialing") {
      setShowWelcomeModal(true);
    }

    const tier = subscription?.metadata?.tier as Tier | undefined;
    if (
      tier === "pro" &&
      subscriptionStatus === "trialing" &&
      !seenProModal &&
      seenWelcomeModal &&
      differenceInHours(new Date(), seenWelcomeModal) >= 24
    ) {
      setShowProModal(true);
    }
  }, [
    seenProModal,
    seenWelcomeModal,
    setSeenWelcomeModal,
    subscription?.metadata?.tier,
    subscriptionStatus,
  ]);

  const handleDismissWelcomeModal = useCallback(() => {
    setSeenWelcomeModal(new Date());
    setShowWelcomeModal(false);
  }, [setSeenWelcomeModal]);

  const handleDismissProModal = useCallback(() => {
    setSeenProModal(new Date());
    setShowProModal(false);
  }, [setSeenProModal]);

  const content = (
    <>
      <UploadModal
        open={isUploadModalOpen}
        toggleModal={setIsUploadModalOpen}
        uploadPath={`/users/${oid}/files/`}
        heapEventName="home_file_uploaded"
      />
      <LinkFormModal
        show={showLinkModal}
        toggleShow={setShowLinkModal}
        isUserFile={true}
        heapEventName="home_link_saved"
      />
      {showWelcomeModal && (
        <WelcomeModal onDismiss={handleDismissWelcomeModal} />
      )}
      {showProModal && (
        <TiersWelcomeModal variant="pro" onDismiss={handleDismissProModal} />
      )}
      {showNewEmailModal && (
        <SendEmailModal
          toggleShow={setShowNewEmailModal}
          show={showNewEmailModal}
        />
      )}
      {canViewGetStartedVideos && <GetStartedVideosSection className="pb-8" />}

      <div className="pb-6">
        <TodaysViewFilterProvider>
          {isOwner ? <DashboardWithOnboarding /> : <DashboardCardsList />}
        </TodaysViewFilterProvider>
      </div>

      <Suspense fallback={<LoadingSpinner className="mx-auto mt-32" />}>
        <AppointmentList title="Upcoming appointments" showOnlyUpcoming />
      </Suspense>
    </>
  );

  return (
    <AppFrame
      variant="main"
      title="Home"
      actions={[
        ...(hasCalendarIntegrated
          ? [
              {
                icon: <CalendarIcon />,
                text: "New appointment",
                href: "/appointments/create",
                heapEventName: "home_new_appointment",
              },
            ]
          : []),
        ...(hasElevatedAccess
          ? [
              {
                icon: <ClientIcon />,
                text: "New client",
                href: "/contacts/create",
                heapEventName: "home_new_client",
              },
            ]
          : []),
        ...(organization?.stripe?.stripe_user_id && hasElevatedAccess
          ? [
              {
                icon: <InvoiceIcon />,
                text: "New invoice",
                href: "/invoices/create",
                heapEventName: "home_new_invoice",
              },
            ]
          : []),
        {
          text: "New form",
          icon: <FormIcon />,
          href: "/contacts/forms/create",
          heapEventName: "home_new_form",
        },
        ...(hasElevatedAccess
          ? [
              {
                text: "New files",
                icon: <UploadIcon />,
                onClick: () => setIsUploadModalOpen(true),
                heapEventName: "home_new_file",
              },
            ]
          : []),
        ...(hasElevatedAccess
          ? [
              {
                text: "New link",
                icon: <SocialWebIcon className="w-6 h-6 text-black-ink" />,
                onClick: () => setShowLinkModal(true),
                heapEventName: "home_new_link",
              },
            ]
          : []),
        ...(hasEmail(account)
          ? [
              {
                icon: <EmailIcon />,
                onClick: () => setShowNewEmailModal(true),
                text: "Send email",
                heapEventName: "home_new_email",
              },
            ]
          : []),
      ]}
    >
      {content}
    </AppFrame>
  );
};

export default Home;
