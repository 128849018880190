import { FC, useState } from "react";
import classNames from "classnames";
import { compact } from "lodash";

import useAccessType from "@hooks/use-access-type";
import useOrganizationAccounts from "@hooks/use-organization-accounts";
import { displayNameFromContact } from "@lib/contacts";

import Listbox, { ListboxOption, useSimpleSearchProps } from "./Listbox";

interface MemberFilterProps {
  defaultValue?: string;
  setFilters: (filters: Record<string, string>) => void;
  listboxClassNames?: string;
}

const MemberFilter: FC<MemberFilterProps> = ({
  defaultValue,
  setFilters,
  listboxClassNames,
}) => {
  const { hasFullAccess } = useAccessType();
  const [memberId, setMemberId] = useState<string>(
    defaultValue ?? "all-members"
  );

  const { accounts } = useOrganizationAccounts();

  const memberOptions = compact(
    accounts?.map((acc) => {
      return {
        title: displayNameFromContact(acc),
        value: acc.id,
      };
    })
  );

  const allOptions = [
    {
      title: "All members",
      value: "all-members",
    },
    ...memberOptions,
  ];

  const { searchProps, filteredOptions } = useSimpleSearchProps(
    allOptions,
    "Search members..."
  );
  if (!hasFullAccess || accounts?.length === 1) return null;

  const formatOptionLabel = (member) => {
    return (
      <div className="flex items-center">
        <p className="font-medium text-sm text-black-ink text-ellipsis overflow-hidden">
          {member.title}
        </p>
      </div>
    );
  };

  const handleMemberChange = (val) => {
    setMemberId(val);
    const value = val === "all-members" ? undefined : val;
    setFilters({
      memberId: value,
    });
  };
  const member = allOptions.find((member) => member.value === memberId);

  return (
    <Listbox
      onChange={handleMemberChange}
      placeholder={formatOptionLabel(member ?? { title: "Select a member" })}
      value={memberId}
      className="!w-60 !space-y-0"
      customListboxClassNames={classNames(
        "!bg-grey-950 !border-none !h-[30px]",
        listboxClassNames
      )}
      searchProps={
        searchProps
          ? { ...searchProps, searchInputClassNames: "bg-white" }
          : undefined
      }
    >
      {filteredOptions?.map((member) => (
        <ListboxOption
          key={member.value}
          primaryText={member.title}
          value={member.value}
          className="bg-white hover:bg-action-900 hover:cursor-pointer !p-3"
        >
          {formatOptionLabel(member)}
        </ListboxOption>
      ))}
    </Listbox>
  );
};

export default MemberFilter;
