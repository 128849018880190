import { useCallback, useMemo } from "react";
import { getAppointments } from "api-services/definitions/appointments";
import { useApiGetInfinite } from "api-services/endpoints";
import { uniqBy } from "lodash";
import { DateTime } from "luxon";

import { filterIsEvent, filterIsNotEvent } from "@lib/appointments";
import { AccountType } from "@lib/data/schemas/account";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { ClientType } from "@lib/data/schemas/client";
import { GroupType } from "@lib/data/schemas/group";
import { OutcomeType } from "@lib/data/schemas/outcome";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { filterRecurringDeclinedAndArchivedContactAppointments } from "@lib/utils/appointments";
import {
  groupAppointments,
  GroupedAppointments,
} from "@lib/utils/appointments/groupAppointments";

import { useSchedulers } from "./data/schedulers";

interface UseAppointmentsProps {
  oid: string | undefined;
  isPast?: boolean;
  onlyEvents?: boolean;
  memberId?: string;
  showHidden?: boolean;
  withoutOutcome?: boolean;
  withoutPackage?: boolean;
  schedulerId?: string;
  onlyGroupSchedulerAppointments?: boolean;
}

export type Appointment = AppointmentType & {
  contacts?: ClientType[];
  group?: GroupType;
  organizer?: AccountType;
  memberIds: string[];
  appointmentOutcome?: OutcomeType;
};

const usePaginatedAppointments = ({
  oid,
  isPast,
  onlyEvents,
  memberId,
  showHidden,
  withoutOutcome,
  withoutPackage,
  schedulerId,
  onlyGroupSchedulerAppointments,
}: UseAppointmentsProps): {
  appointments?: Appointment[];
  loading: boolean;
  loadMore: () => void;
  totalAppts: number;
  totalEvent: number;
  groupedAppointments: GroupedAppointments;
} => {
  const LIMIT = 20;

  const { data: appointmentTypes } = useSchedulers();

  const { data, loading, setSize, size } = useApiGetInfinite(
    getAppointments,
    { orgId: oid! },
    {
      ...(isPast && { isPast: `${isPast}` }),
      ...(onlyEvents && { onlyEvents: `${onlyEvents}` }),
      ...(showHidden && { showHidden: `${showHidden}` }),
      ...(memberId && { memberId }),
      ...(schedulerId && { schedulerId }),
      ...(onlyGroupSchedulerAppointments && {
        onlyGroupSchedulerAppointments: `${onlyGroupSchedulerAppointments}`,
      }),
      limit: LIMIT.toString(),
    },
    {
      dedupingInterval: 18000,
    }
  );

  const appointments = data?.flatMap((page) => page.data) as Appointment[];

  const fixedAppointments = useMemo(() => {
    const rawData = appointments
      ?.filter((item) => (withoutOutcome ? !item.appointmentOutcome : true))
      ?.filter((item) => (withoutPackage ? !item.packageInstanceId : true))
      ?.filter((item) => (showHidden ? true : !item.hidden))
      ?.filter(filterRecurringDeclinedAndArchivedContactAppointments)
      ?.map((appointment) => {
        return fixAppointment(appointment, oid, appointmentTypes);
      });
    return uniqBy(rawData, "id");
  }, [
    appointments,
    oid,
    appointmentTypes,
    showHidden,
    withoutOutcome,
    withoutPackage,
  ]);

  const loadMore = useCallback(() => {
    if (loading) return;
    setSize(size + 1);
  }, [loading, setSize, size]);

  const totalAppts = fixedAppointments?.filter(filterIsNotEvent)?.length ?? 0;
  const totalEvent = fixedAppointments?.filter(filterIsEvent)?.length ?? 0;

  const groupedAppointments = groupAppointments(fixedAppointments ?? []);

  return {
    appointments: fixedAppointments,
    groupedAppointments,
    loading,
    loadMore,
    totalAppts,
    totalEvent,
  };
};

const fixAppointment = (
  appointment: AppointmentType,
  oid: string | undefined,
  appointmentTypes: SchedulerType[] | undefined
) => {
  let appointmentTypeTitle: string | undefined = undefined;
  let appointmentTypeDuration: number | undefined = undefined;
  let icon: string | undefined = undefined;

  if (appointment.availabilityId) {
    const associatedAppointmentType = appointmentTypes?.find(
      (at) => at.id === appointment.availabilityId
    );

    if (associatedAppointmentType) {
      appointmentTypeTitle = associatedAppointmentType.title;
      appointmentTypeDuration = associatedAppointmentType.duration || 0;
      if (associatedAppointmentType.icon) {
        icon = associatedAppointmentType.icon;
      } else {
        icon = "ill_calendar";
      }
    }
  }

  const offset = appointment.allDayEvent
    ? new Date().getTimezoneOffset() * 60 * 1000
    : 0;

  const start =
    typeof appointment.start === "string"
      ? new Date(appointment.start)
      : appointment.start;
  const end =
    typeof appointment.end === "string"
      ? new Date(appointment.end)
      : appointment.end;

  const ISOstart = DateTime.fromMillis(start.getTime() + offset).toISO();
  const ISOend = DateTime.fromMillis(end.getTime() + offset).toISO();

  return {
    ...appointment,
    memberIds: appointment.memberIds ?? [],
    start,
    end,
    appointmentTypeTitle,
    appointmentTypeDuration,
    icon,
    ISOstart,
    ISOend,
  };
};

export default usePaginatedAppointments;
