import React, { useEffect } from "react";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import { useContactsNumbers } from "@hooks/data/clients";
import { useGetCalendars } from "@hooks/use-get-integrations";
import useOrganization from "@hooks/use-organization";

import LoadingSpinner from "@components/LoadingSpinner";

import AppointmentsDashboardCard from "./AppointmentsDashboardCard";
import ClientsDashboardCard from "./ClientsDashboardCard";
import DashboardCardsList from "./DashboardCardsList";
import PaymentsDashboardCard from "./PaymentsDashboardCard";
import StepIndicator from "./StepIndicator";

const DashboardWithOnboarding = () => {
  const { organization, oid } = useAuth();
  const { update } = useOrganization(oid);

  const { loading: loadingContacts, active, total } = useContactsNumbers();
  const { accounts } = useGetCalendars(false);

  const clientsNumbers = {
    total: total,
    active: active,
  };

  const hasCalendarIntegrated = React.useMemo(
    () => !!accounts?.length,
    [accounts]
  );

  const hasClientAdded = (clientsNumbers.active ?? 0) > 0;
  const hasStripeIntegrated = organization?.stripe?.stripe_user_id;
  const dashboardStep = React.useMemo(() => {
    if (
      hasCalendarIntegrated &&
      hasClientAdded &&
      (hasStripeIntegrated || organization?.dashboard?.payments === "dismissed")
    )
      return 4;
    if (hasCalendarIntegrated && hasClientAdded) return 3;
    if (hasCalendarIntegrated) return 2;
    if (!hasCalendarIntegrated) return 1;
    return 4;
  }, [
    hasCalendarIntegrated,
    hasClientAdded,
    hasStripeIntegrated,
    organization?.dashboard?.payments,
  ]);

  useEffect(() => {
    if (organization?.stripe?.stripe_user_id)
      update({
        dashboard: {},
        updatedAt: new Date(),
      });
  }, [organization?.stripe?.stripe_user_id]);

  if (dashboardStep === 4) return <DashboardCardsList />;

  const loading =
    !accounts || loadingContacts || !organization || !clientsNumbers;

  if (loading)
    return (
      <div className="w-full flex justify-center py-8">
        <LoadingSpinner />
      </div>
    );

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-black-ink text-xl font-regular">
          {
            {
              1: "You’re nearly there!",
              2: "Two more steps to go",
              3: "Almost there, one last step",
              4: "Activity dashboard",
            }[dashboardStep]
          }
        </h2>
        <StepIndicator step={dashboardStep} />
      </div>

      <p className="text-grey-500 text-sm leading-5">
        Follow these 3 steps to get your Practice set up.
      </p>

      <div className="my-6">
        <div
          className={classNames(
            "grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
          )}
        >
          <AppointmentsDashboardCard
            isStatic={[1].includes(dashboardStep)}
            isSelected={dashboardStep === 1}
          />
          <ClientsDashboardCard
            isStatic={[1, 2].includes(dashboardStep)}
            isSelected={dashboardStep === 2}
          />
          <PaymentsDashboardCard
            isStatic={[1, 2, 3].includes(dashboardStep)}
            isSelected={dashboardStep === 3}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardWithOnboarding;
