import React from "react";

import { useAuth } from "@contexts/auth";

import ActiveDashboardCard from "@components/Home/ActiveDashboardCard";
import StaticDashboardCard from "@components/Home/StaticDashboardCard";
import CompletedStatusIcon from "@components/Icons/CompletedStatusIcon";

interface PaymentsDashboardCardProps {
  className?: string;
  isStatic: boolean;
  isSelected: boolean;
}

const PaymentsDashboardCard: React.FC<PaymentsDashboardCardProps> = ({
  className,
  isStatic,
  isSelected,
}) => {
  const { oid } = useAuth();

  if (isStatic) {
    return (
      <StaticDashboardCard
        className={className}
        href={`/api/v1/users/${oid}/stripe/auth?platform=web`}
        title="Payments"
        number={3}
        description="Create and share your invoices with your clients."
        buttonText="Link Stripe"
        isSelected={isSelected}
      />
    );
  }

  return (
    <ActiveDashboardCard
      className={className}
      href="/transactions"
      title="Payments"
      description="Successfully connected"
      DescriptionIcon={CompletedStatusIcon}
    />
  );
};

export default PaymentsDashboardCard;
