import React from "react";

import ActiveDashboardCard from "@components/Home/ActiveDashboardCard";
import StaticDashboardCard from "@components/Home/StaticDashboardCard";
import ActiveStatusIcon from "@components/Icons/ActiveStatusIcon";

interface ClientsDashboardCardProps {
  className?: string;
  isStatic: boolean;
  isSelected: boolean;
}

const ClientsDashboardCard: React.FC<ClientsDashboardCardProps> = ({
  className,
  isStatic,
  isSelected,
}) => {
  if (isStatic) {
    return (
      <StaticDashboardCard
        className={className}
        href="/contacts/import"
        title="Clients"
        number={2}
        description="Track, manage and organize your client list."
        buttonText="Add Clients"
        isSelected={isSelected}
      />
    );
  }

  return (
    <ActiveDashboardCard
      className={className}
      href="/contacts"
      title="Contacts"
      description="Clients added"
      DescriptionIcon={ActiveStatusIcon}
    />
  );
};

export default ClientsDashboardCard;
